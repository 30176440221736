import { Head, PageLayout, Startseite } from 'components/molecules';
import { getTranslations } from 'i18n';
import type { GetServerSideProps, NextPage } from 'next';
import React from 'react';
import { FeaturedProfiles } from 'types';
import { getCanonicalUrl } from 'utils';
import { useIsPlus, useTranslation } from 'utils/hooks';

interface StartPageProps
{
  featuredProfiles: FeaturedProfiles;
}

const StartPage: NextPage<StartPageProps> = (props) => 
{
  const { featuredProfiles } = props;
  const { t } = useTranslation();

  const isPlus = useIsPlus();

  return (
    <PageLayout>

      <Head
        title={t('dashboard:meta.title')}
        description={t('dashboard:meta.description')}
      >
        <link rel="canonical" href={getCanonicalUrl()} />
        <meta name="keywords" content={t('dashboard:meta.keywords')} />
      </Head>

      <Startseite>
        {isPlus ? (
          <Startseite.Grid columns={3}>
            <Startseite.Subscriptions />
            <Startseite.PersonalUpdates />
          </Startseite.Grid>
        ) : (
          <Startseite.Statistics />
        )}

        <Startseite.Grid columns={3} id={'listen'}>
          <Startseite.FeaturedProfilesCard
            label={t('startseite:featured.neueintragungen')}
            profiles={featuredProfiles.neueintragungen}
            showAllHref={'/l/neueintragungen'}
          />
          <Startseite.FeaturedProfilesCard
            label={t('startseite:featured.insolvenzen')}
            profiles={featuredProfiles.insolvenzen}
            showAllHref={'/l/insolvenzen'}
          />
          <Startseite.FeaturedProfilesCard
            label={t('startseite:featured.scheinunternehmen')}
            profiles={featuredProfiles.scheinunternehmen}
            showAllHref={'/l/scheinfirmen'}
          />
        </Startseite.Grid>

        <Startseite.Blog />

        {!isPlus && (<>
          <Startseite.Plus />
          <Startseite.Grid columns={3}>
          <Startseite.DataInfo />
          <Startseite.Sources />
        </Startseite.Grid>
          <Startseite.Grid columns={2} gap="4rem">
            <Startseite.StepsCta />
            <Startseite.About />
          </Startseite.Grid>
          <Startseite.Grid columns={3}>
            <Startseite.NewsletterCard />
            <Startseite.FeaturedProfilesCard
              label={t('startseite:featured.top')}
              profiles={featuredProfiles.top}
            />
            <Startseite.FeaturedProfilesCard
              label={t('startseite:featured.new')}
              profiles={featuredProfiles.newest}
            />
          </Startseite.Grid>
        </>)}

        <Startseite.Socials />

      </Startseite>

    </PageLayout>
  );
};

export default StartPage;

export const getServerSideProps: GetServerSideProps = async (ctx) =>
{
  const { locale } = ctx;
  const ns = ['dashboard', 'startseite', 'forms', 'funktionen', 'products', 'user', 'änderungen'];
  const translations = await getTranslations(locale, ...ns);

  let featuredProfiles: FeaturedProfiles = {
    newest: [],
    top: [],
    neueintragungen: [],
    insolvenzen: [],
    scheinunternehmen: [],
  };

  try
  {
    const res = await fetch(`${process.env.API_ENDPOINT}/api/seo/featured`);

    if (res.ok)
    {
      featuredProfiles = await res.json();
    }
  }
  catch
  {
    console.error("Couldn't fetch featured pages");
  }
  
  return { props: { ...translations, featuredProfiles } };
};
